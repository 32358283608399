import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import Modal from 'react-modal'
import history from '../history'
import { useUserPlant, useUserTestZones } from '../hooks/User'
import { unsetModal } from '../actions/modal-actions'
import { BLACK_COLOR, GRAY_COLOR } from '../constants'

import CloseButton from './CloseButton'
import { update } from 'lodash'
import { useGetUserTestZones } from '../hooks/TestZones'

const SelectTestZones = ({ intl, isOpen, closeModal }) => {
  const location = useLocation()
  const [userPlant] = useUserPlant()
  const [userTestZones, setUserTestZones] = useUserTestZones()
  const { isLoading, allTestZones } = useGetUserTestZones()
  const [, , remove] = useLocalStorage('reports-query')
  const [selectedTestZones, selectTestZones] = useState(userTestZones)
  const [testZonesModified, setTestZonesModified] = useState(false)

  const availableTestZones = allTestZones.filter(
    (t) => t.plant.name.toLowerCase() == userPlant.toLowerCase(),
  )

  const isTestZoneSelected = (testZone) => {
    return selectedTestZones.some((t) => t.specifier === testZone)
  }

  const updateSelectedTestZones = (testZone) => {
    const currentlySelected = isTestZoneSelected(testZone)
    if (currentlySelected) {
      // remove the test zone
      selectTestZones(selectedTestZones.filter((t) => t.specifier !== testZone))
    } else {
      // add the test zone
      const toAdd = availableTestZones.filter((t) => t.specifier === testZone)
      selectTestZones(selectedTestZones.concat(toAdd))
    }
    setTestZonesModified(true)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-state-content"
      overlayClassName="modal-overlay"
      contentLabel="Select Test Zones"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault()
          remove()
          setUserTestZones(selectedTestZones)
          closeModal()
          if (location.pathname == '/reports') history.go(0)
        }}
        id="select-testzone-form"
      >
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="row">
              <div className="col-xs-8">
                <div className="panel-title">
                  <h4>
                    <FormattedMessage
                      id="selectTestZones.title"
                      defaultMessage="Select Test Zones"
                    />
                  </h4>
                </div>
              </div>
              <div className="col-xs-4">
                <CloseButton
                  wrapperClassName="close-button-container"
                  onClose={closeModal}
                />
              </div>
            </div>
          </div>
          <div className="panel-body">
            <div className="row" style={{ color: GRAY_COLOR }}>
              <FormattedMessage
                id="selectTestZones.leadingText"
                defaultMessage="Please choose test zones"
              />
            </div>
            <div className="row">
              <div className="btn-group-vertial" role="group">
                {isLoading && (
                  <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                )}
                {!isLoading &&
                  availableTestZones.map((availableTestZone) => {
                    return (
                      <div
                        className="checkbox"
                        key={availableTestZone.description}
                        id={availableTestZone.specifier}
                      >
                        <label style={{ color: BLACK_COLOR }}>
                          <input
                            type="checkbox"
                            name="selectTestZones"
                            id={availableTestZone.specifier}
                            value={availableTestZone.specifier}
                            checked={isTestZoneSelected(
                              availableTestZone.specifier,
                            )}
                            onChange={() =>
                              updateSelectedTestZones(
                                availableTestZone.specifier,
                              )
                            }
                          />
                          {availableTestZone.description}
                        </label>
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className="row">
              <div className="modal-btn-group text-center">
                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-cancel"
                    onClick={closeModal}
                  >
                    <FormattedMessage id="forms.cancel" />
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    disabled={!testZonesModified}
                  >
                    <FormattedMessage id="forms.submit" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.modalState.isOpen,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeModal: () => {
      dispatch(unsetModal())
    },
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SelectTestZones),
)
