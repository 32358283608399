import axios from 'axios'
import { useQuery } from 'react-query'

export const useGetUserTestZones = () => {
  const {
    status,
    data: allTestZones,
    error,
    isLoading,
  } = useQuery(
    'user-test-zones',
    async () => {
      const result = await axios.get('/tracer/me/test-zones')
      return result.data.data
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return { status, allTestZones, isLoading, error }
}
