import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import history from '../history'
import PropTypes from 'prop-types'

import ChangeStartView from '../components/ChangeStartView'
import ComponentStateModalContainer from '../components/ComponentState/ComponentStateModalContainer'
import ConnectorViewModalContainer from '../containers/ConnectorViewModalContainer'
import DeviceStateModalContainer from '../components/ComponentState/DeviceStateModalContainer'
import FeedbackForm from '../components/FeedbackForm'
import HarnessStateModalContainer from '../components/ComponentState/HarnessStateModalContainer'
import PinDestinationContainer from '../containers/PinDestinationContainer'
import RepairHistoryContainer from './RepairHistory/RepairHistoryContainer'
import SameIssuesHistoryContainer from '../containers/SameIssuesHistoryContainer'
import SwitchTestContainer from '../containers/SwitchTestContainer'
import ConfirmationModalContainer from '../containers/VerifyComponentConfirmationModalContainer'
import CantFixForm from '../components/CantFixForm'
import SelectPlant from '../components/SelectPlant'
import SelectStationAlert from './SelectStationAlert'
import SelectTestZones from './SelectTestZones'
import PinDestinationEmbeddedMode from '../EmbeddedMode/PinDestination'
import ConnectorViewEmbeddedMode from '../EmbeddedMode/ConnectorViewEmbeddedMode'
import AddFault from './RepairHistory/AddFault'
import { CantSavePopup } from './DTCMapping/AddConnectorMappingStartComponent'
import DTCDependencyInfo from './DTCDependencyInfo'

import {
  MODAL_COMPONENT_STATE,
  MODAL_CHANGE_START_VIEW,
  MODAL_DEVICE_STATE,
  MODAL_HARNESS_STATE,
  MODAL_PIN_DESTINATION,
  MODAL_REPAIR_HISTORY,
  MODAL_CONNECTOR_VIEW,
  MODAL_EMBEDDED_MODE_CONNECTOR_VIEW,
  MODAL_SWITCH_TEST,
  MODAL_SAME_ISSUES_HISTORY,
  MODAL_SUBMIT_FEEDBACK,
  MODAL_CONFIRM_VERIFY_COMPONENT,
  MODAL_CANT_FIX,
  MODAL_EMBEDDED_MODE_PIN_DESTINATION,
  MODAL_CANT_SAVE_START_COMPONENT,
  MODAL_ADD_FAULT,
  MODAL_SELECT_PLANT,
  MODAL_SELECT_TESTZONE,
  MODAL_SELECT_STATION_ALERT,
  MODAL_DTC_DEPENDENCY_INFO,
} from '../constants'

const propTypes = {
  availableModals: PropTypes.object,
  containers: PropTypes.array,
  isOpen: PropTypes.bool,
}

const defaultProps = {
  availableModals: {
    [MODAL_COMPONENT_STATE]: ComponentStateModalContainer,
    [MODAL_DEVICE_STATE]: DeviceStateModalContainer,
    [MODAL_HARNESS_STATE]: HarnessStateModalContainer,
    [MODAL_PIN_DESTINATION]: PinDestinationContainer,
    [MODAL_EMBEDDED_MODE_PIN_DESTINATION]: PinDestinationEmbeddedMode,
    [MODAL_REPAIR_HISTORY]: RepairHistoryContainer,
    [MODAL_CHANGE_START_VIEW]: ChangeStartView,
    [MODAL_CONNECTOR_VIEW]: ConnectorViewModalContainer,
    [MODAL_EMBEDDED_MODE_CONNECTOR_VIEW]: ConnectorViewEmbeddedMode,
    [MODAL_SWITCH_TEST]: SwitchTestContainer,
    [MODAL_SAME_ISSUES_HISTORY]: SameIssuesHistoryContainer,
    [MODAL_SUBMIT_FEEDBACK]: FeedbackForm,
    [MODAL_SELECT_PLANT]: SelectPlant,
    [MODAL_SELECT_TESTZONE]: SelectTestZones,
    [MODAL_SELECT_STATION_ALERT]: SelectStationAlert,
    [MODAL_CONFIRM_VERIFY_COMPONENT]: ConfirmationModalContainer,
    [MODAL_CANT_FIX]: CantFixForm,
    [MODAL_ADD_FAULT]: AddFault,
    [MODAL_CANT_SAVE_START_COMPONENT]: CantSavePopup,
    [MODAL_DTC_DEPENDENCY_INFO]: DTCDependencyInfo,
  },
  containers: null,
  isOpen: false,
}

class Modal extends Component {
  componentWillMount() {
    const { closeModal } = this.props
    this.unlistenToHistory = history.listen(() => {
      if (closeModal) {
        closeModal()
      }
    })
  }

  componentWillUnmount() {
    this.unlistenToHistory()
  }

  render() {
    if (isEmpty(this.props.containers)) {
      return null
    }

    const containerElements = this.props.containers.map((container) => {
      if (!this.props.availableModals[container]) {
        throw new Error(
          `container '${container}' is not defined in the availableModals dictionary`,
        )
      }
      return React.createElement(
        this.props.availableModals[container],
        { key: container },
        null,
      )
    })

    return <div id="modal">{containerElements}</div>
  }
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal
