import React from 'react'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  DEFAULT_TIME_AFTER_TEST,
  MAX_INPUT_TIME_AFTER_TEST_VALUE,
} from './constants'
import { useGetUserTestZones } from '../hooks/TestZones'

const PlantControl = (props) => (
  <div>
    <p className="control-name">
      <FormattedMessage id="productionLineDashboard.dashboardControl.plant" />
    </p>
    <components.Control {...props} />
  </div>
)

const TestZoneControl = (props) => (
  <div>
    <p className="control-name">
      <FormattedMessage id="productionLineDashboard.dashboardControl.testZone" />
    </p>
    <components.Control {...props} />
  </div>
)

const MaxTimeControl = (props) => (
  <div>
    <p className="control-name">
      <FormattedMessage id="productionLineDashboard.dashboardControl.maxTime" />
    </p>
    <components.Control {...props} />
  </div>
)

const ApplyButton = ({ onClick, disabled }) => (
  <div className="apply-button">
    <div />
    <button
      disabled={disabled}
      className="btn btn-default btn-sm"
      onClick={onClick}
    >
      <FormattedMessage id="productionLineDashboard.dashboardControl.apply" />
    </button>
  </div>
)

function DashboardControl({ searchTerm, setSearchTerm, intl }) {
  const testZoneElemet = React.useRef(null)
  const [plant, setPlant] = React.useState(searchTerm.plant)

  const [testZones, setTestZones] = React.useState(searchTerm.testZones)
  const [timeAfterTest, setTimeAfterTest] = React.useState(
    searchTerm.timeAfterTest,
  )

  const { status, allTestZones, error } = useGetUserTestZones()

  if (status === 'loading') {
    return (
      <span>
        <FormattedMessage id="productionLineDashboard.dashboardControl.loadingTestZone" />
      </span>
    )
  } else if (status === 'error') {
    return (
      <span>
        <FormattedMessage id="productionLineDashboard.dashboardControl.error" />{' '}
        {error.message}
      </span>
    )
  }

  const allPlants = [
    ...new Set(allTestZones.map((zone) => zone.plant.name)),
  ].map((plantName) => ({ value: plantName, label: plantName }))

  const getTestZonesByPlant = ({ testZones, plantName }) =>
    testZones
      .filter((zone) => zone.plant.name === plantName)
      .map((zone) => ({
        value: zone.id,
        label: `${zone.specifier} - ${zone.description}`,
      }))

  // In most of the cases users of this feature will have only one plant.
  // In this case, preselect this plant and corresponding test zones.
  if (!plant && allPlants.length === 1) {
    setPlant(allPlants[0])
    setTestZones(
      getTestZonesByPlant({
        testZones: allTestZones,
        plantName: allPlants[0].value,
      }),
    )
  }

  const onPlantChange = (selectedPlant) => {
    setPlant(selectedPlant)
    setTestZones([])
    testZoneElemet.current.focus()
  }

  const onTestZoneChange = (selectedTestZone) => {
    setTestZones(selectedTestZone)
  }

  const isInteger = (value) => !isNaN(value) && Number.isInteger(Number(value))

  const handleInputChange = (inputValue, { action }) => {
    if (action === 'input-change' && isInteger(inputValue)) {
      if (Number(inputValue) <= MAX_INPUT_TIME_AFTER_TEST_VALUE) {
        setTimeAfterTest({ value: inputValue * 60, label: inputValue })
      }
    }
  }

  const onApplyClick = () => {
    setSearchTerm({ plant, testZones, timeAfterTest })
  }

  return (
    <>
      <div id="production-line-control">
        <Select
          className="plant"
          classNamePrefix="control-select"
          autoFocus={!plant}
          options={allPlants}
          onChange={onPlantChange}
          placeholder={intl.formatMessage({
            id: 'productionLineDashboard.dashboardControl.selectPlantPlaceholder',
          })}
          components={{ Control: PlantControl }}
          value={plant}
        />
        <Select
          ref={testZoneElemet}
          className="test-zone"
          classNamePrefix="control-select"
          closeMenuOnSelect={false}
          isMulti
          options={getTestZonesByPlant({
            testZones: allTestZones,
            plantName: plant ? plant.value : null,
          })}
          onChange={onTestZoneChange}
          value={testZones}
          isDisabled={!plant}
          placeholder={intl.formatMessage({
            id: 'productionLineDashboard.dashboardControl.selectTestZonePlaceholder',
          })}
          components={{ Control: TestZoneControl }}
        />
        <CreatableSelect
          className="time-after-test"
          classNamePrefix="control-select"
          inputValue={timeAfterTest.label}
          defaultInputValue={DEFAULT_TIME_AFTER_TEST.label}
          defaultValue={DEFAULT_TIME_AFTER_TEST}
          menuIsOpen={false}
          components={{ Control: MaxTimeControl, DropdownIndicator: null }}
          value={timeAfterTest}
          onInputChange={handleInputChange}
        />
        <ApplyButton
          disabled={testZones.length === 0 || timeAfterTest.value === 0}
          onClick={onApplyClick}
        />
      </div>
    </>
  )
}

export default injectIntl(DashboardControl)
