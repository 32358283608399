import { useQuery } from 'react-query'
import { apiGetStationInfo } from '../api/station-info'

export const STATIONINFO_KEY = 'stationinfo'

export const useStationInfo = ({ plant_name }) => {
  const {
    status,
    data: stationInfo,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [STATIONINFO_KEY, plant_name],
    async () => {
      const result = await apiGetStationInfo({ plant_name })
      return result.data
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!plant_name,
    },
  )
  return {
    status,
    stationInfo,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}
