import { useQuery } from 'react-query'
import { apiGetTerminology } from '../api/terminology'
import { locale } from '../Locale'

const TERMINOLOGY_KEY = 'terminology'
const TERMINOLOGY_VIN_KEY = 'terminology_vin'

export const useTerminology = ({ makeModelId, language, codes }) => {
  const { data, status, isLoading, isError } = useQuery(
    [TERMINOLOGY_KEY, makeModelId, language, codes],
    async () => {
      const result = await apiGetTerminology({ makeModelId, language, codes })
      return result
    },
    {
      enabled: !!makeModelId && !!language && !!codes,
    },
  )

  return { status, data, isLoading, isError }
}

export const useTerminologyVIN = ({ vin, codes }) => {
  const { data, status, isLoading, isError } = useQuery(
    [TERMINOLOGY_VIN_KEY, vin, locale, codes],
    async () => {
      const result = await apiGetTerminology({ vin, language: locale, codes })
      return result
    },
    {
      enabled: !!vin && !!codes,
    },
  )

  return { status, data, isLoading, isError }
}
