import axiosWrapper, { InterceptorOptions } from './axios-wrapper'

const api = axiosWrapper.create(
  {
    baseURL: '/tracer',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
    },
  },
  InterceptorOptions.defaultErrorInterceptor,
)

export function apiGetStationInfo({ plant_name }) {
  return api
    .get(`/plants/station-info?plantName=${plant_name}`)
    .then((resp) => resp.data)
}
