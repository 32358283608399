import React from 'react'
import { useQuery } from 'react-query'
import { useLocalStorage } from 'react-use'
import { useLocalStorageState } from 'ahooks'
import { getUserInfo } from '../api/auth-api'
import { useGetUserTestZones } from './TestZones'

const KEY = 'user-info'

export const useUserInfo = () => {
  const {
    status,
    data: userInfo,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    [KEY],
    async () => {
      return await getUserInfo()
    },
    {
      refetchOnWindowFocus: false,
    },
  )
  return {
    status,
    userInfo,
    error,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  }
}

export const useUserPlant = () => {
  const { isSuccess, isLoading, userInfo } = useUserInfo()
  const [userPlant, setUserPlant] = useLocalStorage('user-plant', null)

  React.useEffect(() => {
    if (userPlant == null) {
      setUserPlant(userInfo?.plants[0]) // default plant
    }
  }, [userInfo, isSuccess, userPlant, setUserPlant])

  return [userPlant, setUserPlant]
}

export const useUserTestZones = () => {
  const [userPlant] = useUserPlant()
  const storageKey = `user-testzone-plant-${userPlant}`
  const [userTestZones, setUserTestZones] = useLocalStorageState(storageKey, {
    defaultValue: [],
    listenStorageChange: true,
  })

  return [userTestZones, setUserTestZones]
}
