import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'

import { useLockBodyScroll } from 'react-use'
import { FormattedMessage, injectIntl } from 'react-intl'
import VINSearchInput from '../../components/VINSearchInput'
import {
  useVINSearchInputContext,
  useVINSearchInputDispatch,
} from '../VINSearchInput/VINSearchInputContext'
import { requireAuthentication } from '../../helpers/authentication'
import {
  useVirtualVehicleConfiguration,
  useVirtualVehicleConfigurationDispatch,
} from '../../VirtualVehicleConfiguration/VirtualVehicleConfigurationContext'
import {
  MakeModelSelect,
  SalesCodeSelect,
} from '../../VirtualVehicleConfiguration'
import Divider from '../Divider'

import './style.scss'

const VINSearchButton = ({ vin, path, children }) => {
  const history = useHistory()
  return (
    <Button
      type="primary"
      onClick={() => {
        history.push(`/${path}/vin/${vin}`)
      }}
      disabled={!vin}
      data-testid="vin-search-apply-button"
    >
      {children}
    </Button>
  )
}

const VehicleConfigurationSearch = (props) => {
  const { path, intl } = props
  const history = useHistory()
  const virtualVehicleConfiguration = useVirtualVehicleConfiguration()
  const virtualVehicleConfigurationDispatch =
    useVirtualVehicleConfigurationDispatch()
  const vinSearchInputContext = useVINSearchInputContext()
  const vinSearchInputDispatch = useVINSearchInputDispatch()

  useLockBodyScroll(true)

  return (
    <div id="vehicle-configuration-page">
      <div className="vehicle-configuration-container">
        <div className="vehicle-configuration-left-panel">
          <h3>
            <FormattedMessage id="vehicleConfigurationSearch.vehicleConfiguration" />
          </h3>
          <Divider />
          <div className="vin-input" id="vin-input-search-container">
            <VINSearchInput
              disabled={virtualVehicleConfiguration.makeModelID}
            />
          </div>
          <Divider
            text={intl.formatMessage({ id: 'vehicleConfigurationSearch.or' })}
          />
          <div className="vehicle-configuration-make-model-select">
            <MakeModelSelect
              required={false}
              disabled={vinSearchInputContext.inputVIN}
              preselectSalesCodes={false}
            />
          </div>
        </div>
        <div className="vehicle-configuration-center-panel"></div>
        <div className="vehicle-configuration-right-panel">
          <h3>
            <FormattedMessage id="vehicleConfigurationSearch.salesCodes" />
          </h3>
          <Divider />
          <div className="vehicle-configuration-sales-codes-select">
            {virtualVehicleConfiguration.makeModelID && (
              <SalesCodeSelect
                makeModelID={virtualVehicleConfiguration.makeModelID}
              />
            )}
          </div>
        </div>
      </div>
      <div className="vehicle-configuration-bottom-panel">
        {!vinSearchInputContext.completeVIN &&
          !virtualVehicleConfiguration.makeModelID && (
            <Button data-testid="disabled-apply-button" type="primary" disabled>
              <FormattedMessage id="vehicleConfigurationSearch.applyButton" />
            </Button>
          )}

        {vinSearchInputContext.completeVIN && (
          <VINSearchButton vin={vinSearchInputContext.completeVIN} path={path}>
            <FormattedMessage id="vehicleConfigurationSearch.applyButton" />
          </VINSearchButton>
        )}
        {virtualVehicleConfiguration.makeModelID && (
          <VINSearchButton
            vin={virtualVehicleConfiguration.salesCodesPreSelectedVIN}
            path={path}
          >
            <FormattedMessage id="vehicleConfigurationSearch.applyButton" />
          </VINSearchButton>
        )}
        <Button
          onClick={() => {
            vinSearchInputDispatch.reset()
            virtualVehicleConfigurationDispatch.reset()
          }}
          ghost
        >
          <FormattedMessage id="vehicleConfigurationSearch.clearButton" />
        </Button>
      </div>
    </div>
  )
}

export default requireAuthentication(injectIntl(VehicleConfigurationSearch))
