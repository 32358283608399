import React from 'react'
import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { Button } from 'antd'
import { useGetUserTestZones } from '../hooks/TestZones'
import { useUserPlant } from '../hooks/User'
import { DownloadOutlined } from '@ant-design/icons'

const TYPE_FIELDS = {
  component: 'component_id',
  harness: 'harness',
  device: 'device_name',
}

const TYPE_DESCRIPTION = {
  component: 'component_description',
  harness: 'description',
  device: 'device_name',
}

const createExportData = ({ faults, intl, testZones }) => {
  return faults.map((fault) => {
    const userId = intl.formatMessage({
      id: 'faultReport.cvsExportHeaders.userId',
    })
    const date = intl.formatMessage({ id: 'faultReport.cvsExportHeaders.date' })
    const vin = intl.formatMessage({ id: 'faultReport.cvsExportHeaders.vin' })
    const type = intl.formatMessage({ id: 'faultReport.cvsExportHeaders.type' })
    const reason = intl.formatMessage({
      id: 'faultReport.cvsExportHeaders.reason',
    })
    const annotation = intl.formatMessage({
      id: 'faultReport.cvsExportHeaders.annotation',
    })
    const station = intl.formatMessage({
      id: 'faultReport.cvsExportHeaders.station',
    })
    const stationAlert = intl.formatMessage({
      id: 'faultReport.cvsExportHeaders.stationAlert',
    })
    const reportType = intl.formatMessage({
      id: 'faultReport.cvsExportHeaders.reportType',
    })
    const hardwareLocationId = intl.formatMessage({
      id: 'faultReport.cvsExportHeaders.hardwareLocationId',
      defaultMessage: 'Hardware Location ID',
    })
    const testZoneDesc = intl.formatMessage({
      id: 'faultReport.cvsExportHeaders.testZoneDesc',
      defaultMessage: 'Test Zone',
    })
    let data = {}
    data[userId] = fault.created_by
    data[date] = fault.created_at
    data[vin] = fault.vin
    data[type] = fault.type
    data[reason] = fault.reason
    data[annotation] = fault.note
    data[station] = fault.station
    data[stationAlert] = fault.station_alert
    data[reportType] =
      fault[TYPE_FIELDS[fault.type]] +
      ' : ' +
      fault[TYPE_DESCRIPTION[fault.type]]
    data[hardwareLocationId] = fault.hardware_location_id
    data[testZoneDesc] = testZones[fault.hardware_location_id]
    return data
  })
}

const ExportButton = ({ faults, intl }) => {
  const { allTestZones } = useGetUserTestZones()
  const [userPlant] = useUserPlant()
  const [testZonesByLocationId, setTestZonesByLocationId] = React.useState({})
  React.useEffect(() => {
    if (!allTestZones || allTestZones.length === 0 || !userPlant) {
      return
    }
    const tzs = {}
    allTestZones.forEach((tz) => {
      // we only want the ones for the user's plant, there can be
      // multiple with the same specifier but for different plants
      if (tz.plant.name.toLowerCase() === userPlant.toLowerCase()) {
        tzs[tz.specifier] = tz.description
      }
    })
    setTestZonesByLocationId(tzs)
  }, [allTestZones])
  const onClick = () => {
    const data = createExportData({
      faults,
      intl,
      testZones: testZonesByLocationId,
    })
    const dataString = Papa.unparse(data)
    const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' })
    saveAs(blob, 'faults-report.csv')
  }

  return (
    <Button
      disabled={!Array.isArray(faults) || faults?.length === 0}
      onClick={onClick}
      icon={<DownloadOutlined />}
      type="primary"
    >
      {intl.formatMessage({ id: 'faultReport.exportText.exportLabel' })}
    </Button>
  )
}

export default ExportButton
